// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/use-active-order.tsx"
);
import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import { useFetcher, SubmitOptions } from '@remix-run/react';
import { CartLoaderData } from '~/routes/api/active-order';
import { useEffect } from 'react';

const activeOrderPath = '/api/active-order';
const activeOrderOptions: SubmitOptions = {
  method: 'post',
  action: activeOrderPath,
};

export function useActiveOrder() {
  const activeOrderFetcher = useFetcher<CartLoaderData>();
  useEffect(() => {
    if (activeOrderFetcher.state === 'idle' && !activeOrderFetcher.data) {
      activeOrderFetcher.load(activeOrderPath);
    }
  }, [activeOrderFetcher]);

  function refresh() {
    activeOrderFetcher.load(activeOrderPath);
  }

  const { activeOrder } = activeOrderFetcher.data ?? {};
  const removeItem = (lineId: string) => {
    activeOrderFetcher.submit(
      {
        action: 'removeItem',
        lineId,
      },
      activeOrderOptions,
    );
  };
  const adjustOrderLine = (lineId: string, quantity: number) => {
    activeOrderFetcher.submit(
      {
        action: 'adjustItem',
        lineId,
        quantity: quantity.toString(),
      },
      activeOrderOptions,
    );
  };
  const addItemToOrder = (variantId: number, quantity: number) => {
    activeOrderFetcher.submit(
      {
        action: 'addItemToOrder',
        variantId,
        quantity: quantity.toString(),
      },
      activeOrderOptions,
    );
  };
  return {
    activeOrderFetcher,
    activeOrder,
    removeItem,
    adjustOrderLine,
    addItemToOrder,
    refresh,
  };
}
